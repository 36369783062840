/* eslint-disable max-params */
import Types from "./types";
import { CheckoutAEService, b2cVasService } from "../../../service";
import { showToast } from "../../shared/toast-message/actions";
import { TOAST_VARIANT } from "../../shared/toast-message/constant";
import { getSubscriptionOrderActiveStepEndpoint, getSubscriptionOrderActiveStepName } from "./utils";

const initializeCarCareReducer = (source) => {

    return ({
    type: Types.INITIALIZE_CAR_CARE_REDUCER,
    source
});
};

// PAGE DATA : Car Care
const fetchCarCarePageDataInit = (withLoader) => ({
    type: Types.FETCH_CAR_CARE_PAGE_DATA_INIT,
    withLoader
});

const fetchCarCarePageDataSuccess = (data) => ({
    type: Types.FETCH_CAR_CARE_PAGE_DATA_SUCCESS,
    data
});

const fetchCarCarePageDataFailure = () => ({
    type: Types.FETCH_CAR_CARE_PAGE_DATA_FAILURE
});

const fetchCarCarePageData = (withLoader = false) => (dispatch) => {
    dispatch(fetchCarCarePageDataInit(withLoader));
    return new Promise((resolve, reject) => {
        CheckoutAEService.fetchCarCarePageData()
            .then((response) => {
                const data = response?.data;
                dispatch(fetchCarCarePageDataSuccess(data));
                resolve(data);
            })
            .catch((err) => {
                dispatch(fetchCarCarePageDataFailure());
                reject(err);
            });
    });
};

// SUBSCRIPTION All ORDER : Car Care
const fetchAllSubscriptionOrdersInit = (withLoader) => ({
    type: Types.FETCH_ALL_SUBSCRIPTION_ORDER_INIT,
    withLoader
});

const fetchAllSubscriptionOrdersSuccess = (data) => ({
    type: Types.FETCH_ALL_SUBSCRIPTION_ORDER_SUCCESS,
    data
});

const fetchAllSubscriptionOrdersFailure = () => ({
    type: Types.FETCH_ALL_SUBSCRIPTION_ORDER_FAILURE
});

const fetchAllSubscriptionOrders = (tokenPassed, options = { withLoader: false, showErrorToast: false }) => (dispatch, getState) => {
    const { withLoader, showErrorToast } = options || {};
    dispatch(fetchAllSubscriptionOrdersInit(withLoader));
    const { user: { secureToken } } = getState();

    return new Promise((resolve, reject) => {
        CheckoutAEService.fetchAllSubscriptionOrders(tokenPassed || secureToken)
            .then((response) => {
                const data = response?.data;
                dispatch(fetchAllSubscriptionOrdersSuccess(data));
                resolve(data);
            })
            .catch((err) => {
                dispatch(fetchAllSubscriptionOrdersFailure());
                if (showErrorToast) {
                    dispatch(showToast({
                        variant: TOAST_VARIANT.FAILURE,
                        text: "Failed to fetch Subscription Orders"
                    }));
                }
                reject(err);
            });
    });
};

// SUBSCRIPTION ORDER FETCHING : Car Care
const resetSubscriptionOrderData = () => ({
    type: Types.RESET_SUBSCRIPTION_ORDER
});

const fetchSubscriptionOrderDataInit = (withLoader) => ({
    type: Types.FETCH_SUBSCRIPTION_ORDER_INIT,
    withLoader
});

const fetchSubscriptionOrderDataSuccess = (data) => ({
    type: Types.FETCH_SUBSCRIPTION_ORDER_SUCCESS,
    data
});

const fetchSubscriptionOrderDataFailure = () => ({
    type: Types.FETCH_SUBSCRIPTION_ORDER_FAILURE
});

const fetchSubscriptionOrderSummaryData = (orderId, tokenPassed, options = { withLoader: false, showErrorToast: false }) => (dispatch, getState) => {
    const { withLoader, showErrorToast } = options || {};

    dispatch(fetchSubscriptionOrderDataInit(withLoader));
    const { user: { secureToken } } = getState();

    return new Promise((resolve, reject) => {
        CheckoutAEService.fetchSubscriptionOrderSummaryData(orderId, tokenPassed || secureToken)
            .then((response) => {
                const { subscriptionOrder, subscriptionPlan, ...extra } = response?.data;
                const formattedOrderData = { ...subscriptionOrder, subscriptionPlan, ...extra };
                dispatch(fetchSubscriptionOrderDataSuccess(formattedOrderData));
                resolve(formattedOrderData);
            })
            .catch((err) => {
                dispatch(fetchSubscriptionOrderDataFailure());
                if (showErrorToast) {
                    dispatch(showToast({
                        variant: TOAST_VARIANT.FAILURE,
                        text: "Failed to fetch Subscription Order"
                    }));
                }
                reject(err);
            });
    });
};

const fetchSubscriptionOrderData = (orderId, tokenPassed, options = { withLoader: false, showErrorToast: false }) => (dispatch, getState) => {
    const { withLoader, showErrorToast } = options || {};

    dispatch(fetchSubscriptionOrderDataInit(withLoader));
    const { user: { secureToken } } = getState();

    return new Promise((resolve, reject) => {
        CheckoutAEService.fetchSubscriptionOrderData(orderId, tokenPassed || secureToken)
            .then((response) => {
                dispatch(fetchSubscriptionOrderDataSuccess(response?.data));
                resolve(response?.data);
            })
            .catch((err) => {
                dispatch(fetchSubscriptionOrderDataFailure());
                if (showErrorToast) {
                    dispatch(showToast({
                        variant: TOAST_VARIANT.FAILURE,
                        text: "Failed to fetch Subscription Order"
                    }));
                }
                reject(err);
            });
    });
};

// SUBSCRIPTION PLANS : Car Care
const fetchSubscriptionPlansInit = (withLoader) => ({
    type: Types.FETCH_SUBSCRIPTION_PLANS_INIT,
    withLoader
});

const fetchSubscriptionPlansSuccess = (data) => ({
    type: Types.FETCH_SUBSCRIPTION_PLANS_SUCCESS,
    data
});

const fetchSubscriptionPlansFailure = () => ({
    type: Types.FETCH_SUBSCRIPTION_PLANS_FAILURE
});

const fetchSubscriptionPlans = (orderId, tokenPassed, options = { withLoader: false, showErrorToast: false }) => (dispatch, getState) => {
    const { withLoader, showErrorToast } = options || {};
    dispatch(fetchSubscriptionPlansInit(withLoader));
    const { user: { secureToken } } = getState();

    return new Promise((resolve, reject) => {
        CheckoutAEService.fetchSubscriptionPlans(orderId, tokenPassed || secureToken)
            .then((response) => {
                const data = response?.data;
                dispatch(fetchSubscriptionPlansSuccess(data));
                resolve(data);
            })
            .catch((err) => {
                dispatch(fetchSubscriptionPlansFailure());
                if (showErrorToast) {
                    dispatch(showToast({
                        variant: TOAST_VARIANT.FAILURE,
                        text: "Failed to fetch subscription plans"
                    }));
                }
                reject(err);
            });
    });
};

// SUBSCRIPTION Plans Adding to order : Car Care
const addPlanToSubscriptionOrderInit = (withLoader) => ({
    type: Types.ADD_PLAN_TO_SUBSCRIPTION_ORDER_INIT,
    withLoader
});

const addPlanToSubscriptionOrderSuccess = (data) => ({
    type: Types.ADD_PLAN_TO_SUBSCRIPTION_ORDER_SUCCESS,
    data
});

const addPlanToSubscriptionOrderFailure = () => ({
    type: Types.ADD_PLAN_TO_SUBSCRIPTION_ORDER_FAILURE
});

const addPlanToSubscriptionOrder = (orderId, subscriptionPlanDetails,  tokenPassed, options = { withLoader: false, showErrorToast: false }) => (dispatch, getState) => {
    const { withLoader, showErrorToast } = options || {};
    dispatch(addPlanToSubscriptionOrderInit(withLoader));
    dispatch(addPlanToSubscriptionOrderSuccess(subscriptionPlanDetails));
    const { user: { secureToken } } = getState();

    return new Promise((resolve, reject) => {
        CheckoutAEService.addPlanToSubscriptionOrder(orderId, subscriptionPlanDetails,  tokenPassed || secureToken)
            .then((response) => {
                const data = response?.data;
                dispatch(fetchSubscriptionOrderDataSuccess(data));
                resolve(data);
            })
            .catch((err) => {
                dispatch(addPlanToSubscriptionOrderSuccess({}));
                dispatch(addPlanToSubscriptionOrderFailure());
                if (showErrorToast) {
                    dispatch(showToast({
                        variant: TOAST_VARIANT.FAILURE,
                        text: "Failed to Add Plan"
                    }));
                }
                reject(err);
            });
    });
};

// SUBSCRIPTION Plan Activation payment : Car Care
const initiatePaymentForSubscriptionActivation = (orderId, returnUrl,  tokenPassed) => (dispatch, getState) => {

    const { user: { secureToken } } = getState();

    return new Promise((resolve, reject) => {
        CheckoutAEService.initiatePaymentForSubscriptionActivation(orderId, returnUrl,  tokenPassed || secureToken)
            .then((response) => {
                const data = response?.data;
                resolve(data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

// SUBSCRIPTION Plan Re-Activation payment : Car Care
const initiatePaymentForSubscriptionReActivation = (orderId, returnUrl,  tokenPassed) => (dispatch, getState) => {

    const { user: { secureToken } } = getState();

    return new Promise((resolve, reject) => {
        CheckoutAEService.initiatePaymentForSubscriptionReActivation(orderId, returnUrl,  tokenPassed || secureToken)
            .then((response) => {
                const data = response?.data;
                resolve(data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

// SUBSCRIPTION Order creation : Car Care
const creatSubscriptionOrder = (payload,  tokenPassed) => (dispatch, getState) => {

    const { user: { secureToken } } = getState();

    return new Promise((resolve, reject) => {
        CheckoutAEService.creatSubscriptionOrder(payload,  tokenPassed || secureToken)
            .then((response) => {
                const data = response?.data;
                dispatch(fetchSubscriptionOrderDataSuccess(data));
                resolve(data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

// SUBSCRIPTION help Nudge Management
const setShowHelpNudgeForCarCare = (showHelpNudge) => ({
    type: Types.SET_SHOW_HELP_NUDGE,
    showHelpNudge
});

// SUBSCRIPTION Active step Data connect
const fetchSubscriptionOrderActiveStepDataInit = (stepName, withLoader) => ({
    type: Types.FETCH_ACTIVE_STEP_DATA_INIT,
    stepName,
    withLoader
});

const fetchSubscriptionOrderActiveStepDataSuccess = (data) => ({
    type: Types.FETCH_ACTIVE_STEP_DATA_SUCCESS,
    data
});

const fetchSubscriptionOrderActiveStepDataFailure = () => ({
    type: Types.FETCH_ACTIVE_STEP_DATA_FAILURE
});

const fetchSubscriptionOrderActiveStepData = ({
        stepType,
        subType,
        subscriptionOrderId,
        serviceKey,
        serviceId,
        tokenPassed,
        updateInRedux = true,
        paramsToPass = { },
        options = { withLoader: true, showErrorToast: false }
    }) => (dispatch, getState) => {

    const { withLoader, showErrorToast } = options || { withLoader: true, showErrorToast: false };
    const stepName = getSubscriptionOrderActiveStepName(stepType, subType);
    const { serviceName, endPoint, params } = getSubscriptionOrderActiveStepEndpoint({ stepType, subType, subscriptionOrderId, serviceKey, serviceId });

    const paramsParsed = { ...(params || {}), ...(paramsToPass || {}) };

    if (updateInRedux) {
        dispatch(fetchSubscriptionOrderActiveStepDataInit(stepName, withLoader));
    }

    const { user: { secureToken } } = getState();

    const getApiCall = () => {
        if (serviceName === "VAS") {
            return b2cVasService.fetchSubscriptionOrderActiveStepData(endPoint, tokenPassed || secureToken, paramsParsed || {});
        }
        return CheckoutAEService.fetchSubscriptionOrderActiveStepData(endPoint, tokenPassed || secureToken, paramsParsed || {});
    };

    if (endPoint) {
        return new Promise((resolve, reject) => {
            getApiCall()
                .then((response) => {
                    const data = response?.data;
                    if (updateInRedux) {
                        dispatch(fetchSubscriptionOrderActiveStepDataSuccess(data));
                    }
                    resolve(data);
                })
                .catch((err) => {
                    if (updateInRedux) {
                        dispatch(fetchSubscriptionOrderActiveStepDataFailure());
                    }
                    if (showErrorToast) {
                        dispatch(showToast({
                            variant: TOAST_VARIANT.FAILURE,
                            text: "Failed to fetch Details"
                        }));
                    }
                    reject(err);
                });
        });
    } else {
        return new Promise((resolve) => {
            resolve();
        });
    }

};
// SUBSCRIPTION : unsubscribing API for car care
const unsubscribeCarCareSubscription = ({
        subscriptionOrderId,
        tokenPassed,
        payLoad = { },
        options = { showSuccessText: true, showErrorToast: false }
    }) => (dispatch, getState) => {

    const { showErrorToast, showSuccessText } = options || {};

    const { user: { secureToken } } = getState();

    return new Promise((resolve, reject) => {
        CheckoutAEService.unsubscribeCarCareSubscription(subscriptionOrderId, payLoad || {}, tokenPassed || secureToken)
            .then((response) => {
                const data = response?.data;
                dispatch(fetchSubscriptionOrderDataSuccess(data));
                resolve(data);
                if (showSuccessText) {
                    dispatch(showToast({
                        variant: TOAST_VARIANT.SUCCESS,
                        text: "Cancellation Request Raised"
                    }));
                }

            })
            .catch((err) => {
                if (showErrorToast) {
                    dispatch(showToast({
                        variant: TOAST_VARIANT.FAILURE,
                        text: "Error While Unsubscribing"
                    }));
                }
                reject(err);
            });
    });
};

// SUBSCRIPTION : correct subscription plan
const correctSubscriptionPlan = ({
        subscriptionOrderId,
        tokenPassed,
        payLoad = { },
        options = { showSuccessText: false, showErrorToast: false }
    }) => (dispatch, getState) => {

    const { showErrorToast, showSuccessText } = options || {};

    const { user: { secureToken } } = getState();

    return new Promise((resolve, reject) => {
        CheckoutAEService.correctSubscriptionPlan(subscriptionOrderId, payLoad || {}, tokenPassed || secureToken)
            .then((response) => {
                const data = response?.data;
                dispatch(fetchSubscriptionOrderDataSuccess(data));
                resolve(data);
                if (showSuccessText) {
                    dispatch(showToast({
                        variant: TOAST_VARIANT.SUCCESS,
                        text: "Plan Update Request Raised"
                    }));
                }

            })
            .catch((err) => {
                if (showErrorToast) {
                    dispatch(showToast({
                        variant: TOAST_VARIANT.FAILURE,
                        text: "Error While Updating the Plan"
                    }));
                }
                reject(err);
            });
    });
};

// SUBSCRIPTION : create service request
const createSubscriptionServiceRequest = ({
        subscriptionOrderId,
        tokenPassed,
        payLoad = { },
        options = { showErrorToast: false }
    }) => (dispatch, getState) => {

    const { showErrorToast } = options || {};

    const { user: { secureToken } } = getState();

    return new Promise((resolve, reject) => {
        b2cVasService.createSubscriptionServiceRequest(subscriptionOrderId, payLoad || {}, tokenPassed || secureToken)
            .then((response) => {
                const data = response?.data;
                resolve(data);
            })
            .catch((err) => {
                if (showErrorToast) {
                    dispatch(showToast({
                        variant: TOAST_VARIANT.FAILURE,
                        text: "Error While Creating Service Request"
                    }));
                }
                reject(err);
            });
    });
};

// SUBSCRIPTION : get Active service Requests
const fetchSubscriptionServiceRequests = ({
        subscriptionOrderId,
        tokenPassed,
        payLoad = { }
    }) => (dispatch, getState) => {

    const { user: { secureToken } } = getState();

    return new Promise((resolve, reject) => {
        b2cVasService.fetchSubscriptionServiceRequests(subscriptionOrderId, tokenPassed || secureToken, payLoad || {})
            .then((response) => {
                const data = response?.data;
                resolve(data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

const updateSuperAppVersion = (superAppVersion) => ({
    type: Types.UPDATE_SUPER_APP_VERSION,
    superAppVersion
});

export {
    initializeCarCareReducer,
    fetchCarCarePageData,
    fetchAllSubscriptionOrders,
    fetchSubscriptionOrderData,
    resetSubscriptionOrderData,
    fetchSubscriptionOrderSummaryData,
    fetchSubscriptionPlans,
    addPlanToSubscriptionOrderSuccess,
    addPlanToSubscriptionOrder,
    initiatePaymentForSubscriptionActivation,
    initiatePaymentForSubscriptionReActivation,
    creatSubscriptionOrder,
    setShowHelpNudgeForCarCare,
    fetchSubscriptionOrderActiveStepData,
    unsubscribeCarCareSubscription,
    createSubscriptionServiceRequest,
    correctSubscriptionPlan,
    fetchSubscriptionServiceRequests,
    updateSuperAppVersion
};
