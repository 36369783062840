/* eslint-disable max-statements */
import React from "react";
import PropTypes from "prop-types";

// Styling
import styles from "./styles.css";

// Components

// images
import InfoIcon from "./images/info-icon.svg";
import useCustomHistoryOperations from "../../../hooks/use-custom-history-operations";

// Constants
import { ROUTE_TYPE } from "../car-care/constants";

// Utils
import { generateCarCareRoute } from "../car-care/utils";

// Tracking

const SubscriptionBenefits = ({
    services = [],
    tncText = "Full Terms & Conditions",
    onTncClick
}) => {

    const { customPush } = useCustomHistoryOperations();

    const onServiceDetailsClick = () => {
        if (onTncClick) {
            onTncClick();
        } else {
            customPush(generateCarCareRoute(ROUTE_TYPE.TERMS_CONDITIONS));
        }
    };

    return (
        <div styleName={"styles.benefitsSectionWrapper"} >
            <div styleName={"styles.title styles.shimmer"} >
                INCLUDED BENEFITS
            </div>
            <div styleName={"styles.benefitTileWrapper"}>
                {
                    ((services || []).map((serviceItem, idx) => {
                        const { text, imgSrc } = serviceItem || {};
                        return (
                            <div key={idx} styleName={"styles.benefitTile"}>
                                <div  styleName={"styles.imgIconWrapper"}>
                                    <img src={imgSrc}/>
                                </div>
                                <p>{text}</p>
                            </div>
                        );
                    }))
                }

            </div>
            <div styleName={"styles.subscriptionServiceDetailsNudgeTextWrap"} >
                <img src={InfoIcon} />
                <p onClick={onServiceDetailsClick}>{tncText}</p>
            </div>
        </div>
    );
};

SubscriptionBenefits.propTypes = {
    services: PropTypes.array,
    tncText: PropTypes.string,
    onTncClick: PropTypes.func
};

export default (SubscriptionBenefits);
