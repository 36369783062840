import { createTypes } from "../../../utils/redux/create-types";

export default createTypes(
`
    INITIALIZE_CAR_CARE_REDUCER

    FETCH_CAR_CARE_PAGE_DATA_INIT
    FETCH_CAR_CARE_PAGE_DATA_SUCCESS
    FETCH_CAR_CARE_PAGE_DATA_FAILURE

    FETCH_ALL_SUBSCRIPTION_ORDER_INIT
    FETCH_ALL_SUBSCRIPTION_ORDER_SUCCESS
    FETCH_ALL_SUBSCRIPTION_ORDER_FAILURE

    RESET_SUBSCRIPTION_ORDER
    FETCH_SUBSCRIPTION_ORDER_INIT
    FETCH_SUBSCRIPTION_ORDER_SUCCESS
    FETCH_SUBSCRIPTION_ORDER_FAILURE

    FETCH_SUBSCRIPTION_PLANS_INIT
    FETCH_SUBSCRIPTION_PLANS_SUCCESS
    FETCH_SUBSCRIPTION_PLANS_FAILURE

    ADD_PLAN_TO_SUBSCRIPTION_ORDER_INIT
    ADD_PLAN_TO_SUBSCRIPTION_ORDER_SUCCESS
    ADD_PLAN_TO_SUBSCRIPTION_ORDER_FAILURE

    FETCH_ACTIVE_STEP_DATA_INIT
    FETCH_ACTIVE_STEP_DATA_SUCCESS
    FETCH_ACTIVE_STEP_DATA_FAILURE

    SET_SHOW_HELP_NUDGE

    UPDATE_SUPER_APP_VERSION

`,
{
    prefix: "carcare/"
}
);
