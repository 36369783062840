import React from "react";
// import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CarCare from "./component";
import { fetchCarCarePageData, initializeCarCareReducer, updateSuperAppVersion } from "./actions";
import injectableReducers from "./injectable";
import withReducer from "../../../components/shared/with-reducer";
import { initializeSubscriptionVehicleDetialsReducer } from "../subscription-vehicle-details/actions";
import { setProfileWithToken } from "../../ae.configuration/actions";

export default withReducer(injectableReducers)(({
    carCare: {
        pageData: {
            isCarCarePageDataFetched = false
        } = {},
        showHelpNudge = false
    } = {},
    user: {
        isWebview,
        isLoggedIn
    }
}) => ({
    isCarCarePageDataFetched,
    showHelpNudge,
    isWebview,
    isLoggedIn
}),

dispatch => bindActionCreators({
    initializeCarCareReducerConnect: initializeCarCareReducer,
    initializeSubscriptionVehicleDetialsReducerConnect: initializeSubscriptionVehicleDetialsReducer,
    fetchCarCarePageDataConnect: fetchCarCarePageData,
    setProfileWithTokenConnect: setProfileWithToken,
    updateSuperAppVersionConnect: updateSuperAppVersion
}, dispatch))(CarCare);

