/* eslint-disable max-statements */
import React from "react";
import PropTypes from "prop-types";

// Styling
import styles from "./styles.css";

// Components

// images
import BackIcon from "./images/back-icon.svg";

// Constants

// Utils

// Tracking

const Header = ({
    onBackClick = () => {},
    showBackBtn = true
}) => {

    return (
        <div styleName={`styles.headerWrapper`} >
            <div styleName={`styles.backIconCtn`}>
                {showBackBtn && <img src={BackIcon} alt="back-icon" onClick={onBackClick}/>}
            </div>
            <div styleName={`styles.headingIntroWrap`}>
                <p>Introducing</p>
                <h1>CARS24 Care Plan</h1>
                <p><span>Complete car care from the comfort of your home </span></p>
            </div>
        </div>
    );
};

Header.propTypes = {
    onBackClick: PropTypes.func,
    showBackBtn: PropTypes.bool
};

export default (Header);
