/* eslint-disable complexity */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import loadable from "@loadable/component";

// Components
import PageLoader from "../../shared/page-loader";
import ServiceSubscriptionHome from "../service-subscription-home";
import PostBookingWhatsAppNudge from "../post-booking-whatsapp-nudge";
const ServiceSubscriptionTerms = loadable(() => import("../service-subscription-terms"), { fallback: <PageLoader />});
const CarCarePlanSelectionFlowStepMapper = loadable(() => import("../car-care-plan-selection-flow-step-mapper"), { fallback: <PageLoader />});
const CarCareOrderSummaryStepMapper = loadable(() => import("../car-care-order-summary-step-mapper/"), { fallback: <PageLoader />});
const SubscriptionVehicleDetails = loadable(() => import("../subscription-vehicle-details"), { fallback: <PageLoader />});
const BottomNavigation = loadable(() => import("../bottom-navigation"), {ssr: false});

// Constants
import { ROUTE_SUB_TYPE, ROUTE_TYPE } from "./constants";
import { LOCALSTORAGE_CONSTANTS, PAYMENT_INITIATION_SORUCE_TYPE } from "../../../constants/app-constants";
import { NUDGE_PAGE_TYPE } from "../post-booking-whatsapp-nudge/constants";

// Tracking

// Hooks
import { useHistory } from "react-router";

// Utilities
import parseLocationSearchParams from "../../../utils/helpers/parse-location-search-params";
import { getItem, setItem } from "../../../utils/helpers/storage-crud";
import { generateCarCareRoute } from "./utils";
import { ROUTE_NAME } from "../../../constants/routes-constants";

const CarCare = ({
    fetchCarCarePageDataConnect,
    initializeSubscriptionVehicleDetialsReducerConnect,
    isCarCarePageDataFetched,
    showHelpNudge,
    initializeCarCareReducerConnect,
    isWebview,
    isLoggedIn,
    setProfileWithTokenConnect,
    updateSuperAppVersionConnect
}) => {
    const history = useHistory();
    const queryParams = parseLocationSearchParams(history.location.search);
    const stepType = queryParams?.stepType;
    const subType = queryParams?.subType;
    const helpSource = `carCare-${stepType || ROUTE_TYPE.INTRO}`;

    const [isBottomNavVisible, setIsBottomNavVisible] = useState(false);

    const initializeDynamicInjectedReducers = () => {
        initializeCarCareReducerConnect();
        initializeSubscriptionVehicleDetialsReducerConnect();
    };

    useEffect(() => {

        initializeDynamicInjectedReducers();

        if (!isCarCarePageDataFetched) {
            fetchCarCarePageDataConnect();
        }

        const superAppVersionParam = queryParams?.superAppVersion;
        const superAppVersionStored = getItem("careCareSuperAppVersion");
        const superAppVersionParsed = superAppVersionParam || superAppVersionStored;

        updateSuperAppVersionConnect(superAppVersionParsed || "");
        setItem(superAppVersionParsed, "careCareSuperAppVersion");

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const { token } = queryParams || {};
        if (!isLoggedIn && isWebview) {
            if (token) {
                setProfileWithTokenConnect(token);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoggedIn, isWebview]);

    const checkConfigToShowHelpNudge = () => {
        const stepTypeParsed =  stepType || ROUTE_TYPE.INTRO;

        const flowsWithHelpNudge = [ROUTE_TYPE.TERMS_CONDITIONS, ROUTE_TYPE.ORDER_SUMMARY];

        if (flowsWithHelpNudge.includes(stepTypeParsed)) {
            return showHelpNudge;
        } else {
            return false;
        }
    };

    const renderComponent = () => {

        switch (stepType) {
            case ROUTE_TYPE.INTRO:
                return <ServiceSubscriptionHome isBottomNavVisible={isBottomNavVisible} />;
            case ROUTE_TYPE.TERMS_CONDITIONS:
                return <ServiceSubscriptionTerms />;
            case ROUTE_TYPE.CAR_DETAILS:
                return <SubscriptionVehicleDetails />;
            case ROUTE_TYPE.PLAN_LIST:
                return <CarCarePlanSelectionFlowStepMapper />;
            case ROUTE_TYPE.ORDER_SUMMARY:
                return <CarCareOrderSummaryStepMapper isBottomNavVisible={isBottomNavVisible} />;
            case ROUTE_TYPE.PAYMENT_PROCESSOR: {
                const { redirectStepType, redirectSubType, subscriptionOrderId } = queryParams || {};
                if (redirectStepType && redirectSubType && subscriptionOrderId) {
                    // Payment redirections Handling : only for case of payments initiated from SUBSCRIPTION ADMIN PANEL
                    const paymentDetails = {
                        paymentSource: PAYMENT_INITIATION_SORUCE_TYPE.CAR_CARE,
                        redirectUrl: generateCarCareRoute(redirectStepType, redirectSubType, {
                            subscriptionOrderId
                        })
                    };
                    setItem(paymentDetails, LOCALSTORAGE_CONSTANTS.PAYMENT_INITIATION_SORUCE);
                }
                const { paymentSource, redirectUrl } = getItem(LOCALSTORAGE_CONSTANTS.PAYMENT_INITIATION_SORUCE) || {};
                if (paymentSource === PAYMENT_INITIATION_SORUCE_TYPE.CAR_CARE) {
                    history.push(redirectUrl);
                } else {
                    history.push(generateCarCareRoute());
                }
                return <PageLoader />;
            }
            default:
                return <ServiceSubscriptionHome isBottomNavVisible={isBottomNavVisible} />;
        }
    };
    const showBottomNav = (stepType === ROUTE_TYPE.ORDER_SUMMARY && subType === ROUTE_SUB_TYPE.ORDER_SUMMARY.SUBSCRIPTION_SERVICES_PAGE) || (stepType === ROUTE_TYPE.INTRO || !stepType);
    return (
        <div>
            {renderComponent()}
            {checkConfigToShowHelpNudge() && <PostBookingWhatsAppNudge pageType={NUDGE_PAGE_TYPE.SUBSCRIPTION} source={helpSource} />}
            {!isWebview && showBottomNav &&  <BottomNavigation routeName={ROUTE_NAME.CAR_CARE} isVisibleCB={setIsBottomNavVisible} /> }
        </div>
    );
};

CarCare.propTypes = {
    initializeCarCareReducerConnect: PropTypes.func,
    initializeSubscriptionVehicleDetialsReducerConnect: PropTypes.func,
    fetchCarCarePageDataConnect: PropTypes.func,
    isCarCarePageDataFetched: PropTypes.bool,
    showHelpNudge: PropTypes.bool,
    isWebview: PropTypes.bool,
    isLoggedIn: PropTypes.bool,
    setProfileWithTokenConnect: PropTypes.func,
    updateSuperAppVersionConnect: PropTypes.func
};

export default CarCare;
