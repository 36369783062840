/* eslint-disable max-statements */
import React from "react";
import PropTypes from "prop-types";

// Styling
import styles from "./styles.css";

// Components

// images

// Constants

// Utils

// Tracking

const SubscriptionMonthlyPlanCard = ({
    pricingDetails
}) => {
    const { saveUpto, monthlyEmi, tenure } = pricingDetails || {};

    return (
        <div styleName={"styles.planCardWrapper"}>
            <div styleName={"styles.cardTitle"} >
                Save up to <span styleName={"styles.currency"}>AED</span> {saveUpto}<span styleName={"styles.perYear"}>/yr</span>
            </div>
            <div styleName={"styles.cardBodyText1"}>
                Easy Monthly Payments
            </div>
            <div styleName={"styles.cardBodyText2"}>
                from AED <span styleName={"styles.price"}>{monthlyEmi}</span>{`/mo for ${tenure} months`}<span>*</span>
            </div>
        </div>
    );
};

SubscriptionMonthlyPlanCard.propTypes = {
    pricingDetails: PropTypes.object
};

export default (SubscriptionMonthlyPlanCard);
