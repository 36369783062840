/* eslint-disable max-statements */
/* eslint-disable complexity */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import loadable from "@loadable/component";

// Styling
import styles from "./styles.css";

// Components
import Button from "../../shared/button";
const Login = loadable(() => import("../login"));
import PageLoader from "../../shared/page-loader";

// Hooks
import useCustomHistoryOperations from "../../../hooks/use-custom-history-operations";

// images
import BackIcon from "./images/back-icon.svg";

// Constants
import { ROUTE_PATHS } from "../../../constants/routes-constants";

// Utils

// Tracking
import { trackPlatformCustomEventsAEV2 } from "../../../tracking";
import { subscriptionEvents } from "../../../tracking/msite/b2c-subscription-flow-events";
import { WEBVIEW_EVENTS } from "../../../constants";
import { trackWebViewEvents } from "../../../utils/webview-tracking";

const ServiceSubscriptionLayout = ({
    heading = "",
    showHelpBtn = false,
    isLoginRequired,
    customHeaderComponentRenderer,
    onBackClick,
    customStickyCtaComponentRenderer,
    customStyleStickyCta,  // Example :customStyleStickyCta = "styles.commingSoonCta" [ add your styles in this component styles.css ]
    showStickyCta = true,
    stickCtaText = "UNLOCK PRICE",
    onStickyCtaClick = () => {},
    showLoginFooterNudge = true,
    loginFooterNudgeText = "Already Subscribed?",
    showLoginPopup = false,
    onLoginSuccessCallBack = () => {},
    onLoginCloseCallBack = () => {},
    isLoggedIn,
    isUserDetailsFetched,
    showBackBtn = true,
    children,
    isBottomNavVisible = false
}) => {

    const [showLogin, setshowLogin] = useState(false);
    const { customPush, customGoBack } = useCustomHistoryOperations();

    useEffect(() => {
        setshowLogin(showLoginPopup);
    }, [showLoginPopup]);

    useEffect(() => {
        if (isLoginRequired) {
            if (!isLoggedIn && isUserDetailsFetched) {
                setshowLogin(true);
            } else {
                setshowLogin(false);
            }
        }

    }, [isLoggedIn, isLoginRequired, isUserDetailsFetched]);

    const onLoginSuccess = (res) => {
        setshowLogin(false);
        onLoginSuccessCallBack(res);
    };

    const onLoginClose = (res) => {
        setshowLogin(false);
        onLoginCloseCallBack(res);
    };

    const onHelpClick = () => {
        customPush(`/help-center/?source=${ROUTE_PATHS.carCare}`);
    };

    const showLoginFooterNudgeParsed = showLoginFooterNudge && !(isUserDetailsFetched && isLoggedIn);

    const onBackClickHandler = () => {
        if (onBackClick) {
            onBackClick();
        } else {
            customGoBack();
        }
    };

    const onLogInClick = () => {
        trackWebViewEvents(WEBVIEW_EVENTS.HIDE_TOP_TAB);
        setshowLogin(true);
        trackPlatformCustomEventsAEV2(subscriptionEvents.alreadySubscribedLogin);
    };

    return (
        showLogin ?
            <Login isVisible={showLogin} onSuccess={onLoginSuccess} onClose={onLoginClose} >
                <PageLoader/>
            </Login>
            :
            <div styleName={`styles.ServiceSubscriptionLayoutPageWrapper`} >
                {
                    customHeaderComponentRenderer ?
                        customHeaderComponentRenderer()
                        :
                        <div styleName={`styles.pageHeadingWrapper`} >
                            <div styleName={`styles.pageBackCtn`} >
                                {showBackBtn && <img src={BackIcon} alt="back icon" onClick={onBackClickHandler}/>}
                                <span>{heading}</span>
                            </div>
                            {showHelpBtn &&
                                <div>
                                    <span onClick={onHelpClick}> HELP </span>
                                </div>
                            }
                        </div>
                }
                {children}
                {showStickyCta ?
                    <div styleName={`styles.stickPageCta ${isBottomNavVisible ? "styles.extraBottom" : ""}`} >
                        {customStickyCtaComponentRenderer ?
                            customStickyCtaComponentRenderer()
                            :
                            <div styleName={`styles.defaultCta ${customStyleStickyCta ? customStyleStickyCta : ""}`} >
                                <Button text={stickCtaText} onClick={onStickyCtaClick}/>
                                {showLoginFooterNudgeParsed && <p>{loginFooterNudgeText}&nbsp;<span onClick={onLogInClick}>Log In</span></p>}
                            </div>
                        }
                    </div>
                    :
                    <React.Fragment />
                }
            </div>
    );
};

ServiceSubscriptionLayout.propTypes = {
    heading: PropTypes.string,
    showHelpBtn: PropTypes.bool,
    isLoginRequired: PropTypes.bool,
    customHeaderComponentRenderer: PropTypes.node,
    onBackClick: PropTypes.func,
    customStickyCtaComponentRenderer: PropTypes.func,
    customStyleStickyCta: PropTypes.string,
    showStickyCta: PropTypes.bool,
    stickCtaText: PropTypes.string,
    onStickyCtaClick: PropTypes.func,
    showLoginFooterNudge: PropTypes.bool,
    loginFooterNudgeText: PropTypes.string,
    showLoginPopup: PropTypes.bool,
    onLoginSuccessCallBack: PropTypes.func,
    onLoginCloseCallBack: PropTypes.func,
    isLoggedIn: PropTypes.bool,
    isUserDetailsFetched: PropTypes.bool,
    showBackBtn: PropTypes.bool,
    children: PropTypes.any,
    isBottomNavVisible: PropTypes.bool

};

export default (ServiceSubscriptionLayout);
