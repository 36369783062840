import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";

// Actions

// Component
import ServiceSubscriptionLayout from "./component";

const mapStateToProps = ({
    user: {
        isLoggedIn,
        isFetched: isUserDetailsFetched
    }
}) => ({
    isLoggedIn,
    isUserDetailsFetched
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ServiceSubscriptionLayout));
