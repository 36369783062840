import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import loadable from "@loadable/component";

// Styles
import styles from "./styles.css";

// Image
import IconCall from "../../shared/images/call-button.svg";

// Constants
import { NUMBER, SUSCRIPTION_CALL_NUMBER } from "../../../constants/app-constants";
import { NUDGE_PAGE_TYPE } from "./constants";
import { POST_BC_STEPS, STEPS_STATUS_TYPES } from "../../../constants/ae/post-booking-constants";

// utils
import { trackPlatformCustomEventsAEV2 } from "../../../tracking";
import { daysBetweenDates } from "../../../utils/helpers/get-days-to-date";
import { b2cPostBcPageEvents } from "../../../tracking/msite/b2c-post-bc-page-events";
import { ROUTE_NAME } from "../../../constants/routes-constants";
import { b2cServicingEvents } from "../../../tracking/msite/b2c-servicing-events";

// Component
const PostBookingContactPopup = loadable(() => import("../post-booking-contact-popup"));

const PostBookingWhatsAppNudge = ({
    source = "",
    isShowNeedHelpNudge = false,
    isAllStepsCompleted = false,
    handleCallIconClickCallback = () => {},
    handleContactModalCloseCallback = () => {},
    pageType = NUDGE_PAGE_TYPE.POST_BC,
    postBcDetails,
    serviceOrder
}) => {

    const [isShowContactModal, setIsShowContactModal] = useState(false);
    const [isCloseWANudge, setIsCloseWANudge] = useState(isShowNeedHelpNudge);

    const getPostBcContactPopupProps = () => {
        const { orderId = "", order: { status = "" }, steps: postBcSteps, activeStepData } = postBcDetails || {};
        const { deliveryDate: dateOfDelivery } = activeStepData || {};
        const tokenPyamentStatus = (postBcSteps || [])?.find(({ stepType = "" }) => stepType === POST_BC_STEPS.tokenPayment);
        const { stepStatus: tokenPaymentStatus } = tokenPyamentStatus || {};
        const testDriveProps = (postBcSteps || [])?.find(({ stepType = "" }) => stepType === POST_BC_STEPS.testDrive);
        const { faName, faNumber } = testDriveProps || {};

        const isFaAgentDetailsAvailable = () => {
            if (tokenPaymentStatus !== STEPS_STATUS_TYPES.COMPLETED) return false;
            else if (tokenPaymentStatus === STEPS_STATUS_TYPES.COMPLETED && !isAllStepsCompleted) return true;
            else if (isAllStepsCompleted && daysBetweenDates(new Date(), new Date(dateOfDelivery)) < NUMBER.THIRTY) return true;
            else return false;
        };

        return {
            faName,
            faNumber,
            loadPopupWithAgentDetails: isFaAgentDetailsAvailable(),
            invokeRMClickEvent: () => {
                trackPlatformCustomEventsAEV2(b2cPostBcPageEvents.postBcCallOptionSelected, { order_id: orderId, booking_status: status, option_selected: "RM", source_page: source });
            },
            invokeClickCallEvent: () => {
                trackPlatformCustomEventsAEV2(b2cPostBcPageEvents.postBcCallOptionSelected, { order_id: orderId, booking_status: status, option_selected: "Call", source_page: source });
            },
            invokeWhatsappClickEvent: () => {
                trackPlatformCustomEventsAEV2(b2cPostBcPageEvents.postBcCallOptionSelected, { order_id: orderId, booking_status: status, option_selected: "Chat", source_page: source });
            }
        };
    };

    const getTofServicingContactPopupProps = () => {

        const { serviceAdvisorDetails } = serviceOrder || {};
        const { serviceAdvisor, serviceAdvisorPhone } = serviceAdvisorDetails || {};

        const isFaAgentDetailsAvailable = () => {
            return serviceAdvisor && serviceAdvisorPhone;
        };

        return {
            faName: serviceAdvisor,
            faNumber: serviceAdvisorPhone,
            loadPopupWithAgentDetails: isFaAgentDetailsAvailable()
        };
    };

    const getSubscriptionContactPopupProps = () => {
        return {
            dedicatedCallNumber: SUSCRIPTION_CALL_NUMBER
        };
    };

    const getContactPopupProps = () => {
        switch (pageType) {
            case NUDGE_PAGE_TYPE.POST_BC:
                return getPostBcContactPopupProps();
            case NUDGE_PAGE_TYPE.TOF_SERVICING:
                return getTofServicingContactPopupProps();
            case NUDGE_PAGE_TYPE.SUBSCRIPTION:
                return getSubscriptionContactPopupProps();
            default:
                return {};
        }
    };

    const handleCallIconClick = () => {
        setIsShowContactModal(true);
        if (source === ROUTE_NAME.CAR_SERVICING) {
            trackPlatformCustomEventsAEV2(b2cServicingEvents.servicingPageContactUs);
        }
        handleCallIconClickCallback();
    };

    const handleContactModalClose = () => {
        setIsShowContactModal(false);
        handleContactModalCloseCallback();
        if (source === ROUTE_NAME.CAR_SERVICING) {
            trackPlatformCustomEventsAEV2(b2cServicingEvents.servicingPageContactModal, {eventLabel: "close"});
        }
    };

    useEffect(() => {
        if (isCloseWANudge) {
            window.setTimeout(() => {
                setIsCloseWANudge(false);
            }, NUMBER.FIVE_THOUSAND);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isCloseWANudge]);

    const contactPopupProps = getContactPopupProps();

    return (
        <div styleName={"styles.callWrapMainCtn"}>
            <span styleName={"styles.callWrapCtn"} onClick={handleCallIconClick}>
                {!isShowContactModal && <img src={IconCall} alt="Call Icon" width="40" height="40" />}
                {isCloseWANudge && (
                    <div styleName={"styles.contentWrap"}>
                        <h3>Need Help ?</h3>
                        <p>Connect with your RM</p>
                    </div>
                )}
            </span>

            {isShowContactModal && (
                <PostBookingContactPopup
                    source={source}
                    onClose={handleContactModalClose}
                    {...(contactPopupProps || {})}
                />
            )}
        </div>
    );
};

PostBookingWhatsAppNudge.propTypes = {
    source: PropTypes.string,
    isShowNeedHelpNudge: PropTypes.bool,
    isAllStepsCompleted: PropTypes.bool,
    handleCallIconClickCallback: PropTypes.func,
    handleContactModalCloseCallback: PropTypes.func,
    pageType: PropTypes.string,
    postBcDetails: PropTypes.object,
    serviceOrder: PropTypes.object
};

export default PostBookingWhatsAppNudge;
