import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";

// Actions
import { INITIAL_STATE as subscriptionVehicleDetailsInitialState } from "../subscription-vehicle-details/reducers";

// Component
import ServiceSubscriptionHome from "./component";
import { fetchAllSubscriptionOrders } from "../car-care/actions";

const mapStateToProps = ({
    carCare: {
        pageData = {},
        superAppVersion
    } = {},
    user: {
        isLoggedIn,
        isFetched: isUserDetailsFetched,
        isWebview
    },
    subscriptionVehicleDetails: {
        resumeUrl: subscriptioBuyingResumeUrl
    } = subscriptionVehicleDetailsInitialState
}) => ({
    pageData,
    isLoggedIn,
    isUserDetailsFetched,
    isWebview,
    subscriptioBuyingResumeUrl,
    superAppVersion
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchAllSubscriptionOrdersConnect: fetchAllSubscriptionOrders
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ServiceSubscriptionHome));
