import { createTypes } from "../../../utils/redux/create-types";

export default createTypes(
    `

    INITIALIZE_SUBSCRIPTION_VEHICLE_DETAILS_REDUCER

    SET_EVALUATION_BRAND
    SET_EVALUATION_MODEL
    SET_EVALUATION_YEAR
    SET_EVALUATION_CUSTOMER
    SET_EVALUATION_DATA
    SET_EVALUATION_DATA_BY_VARIANTS
    SET_BOOKING_DETAILS

    CREATE_LEAD_APPOINTMENT_REQUEST
    CREATE_LEAD_APPOINTMENT_SUCCESS
    CREATE_LEAD_APPOINTMENT_FAILURE

    UPDATE_LEAD_APPOINTMENT_SUCCESS
    UPDATE_LEAD_APPOINTMENT_FAILURE
    UPDATE_LEAD_APPOINTMENT_REQUEST

    FETCH_LEAD_APPOINTMENT_INFO_REQUEST
    FETCH_LEAD_APPOINTMENT_INFO_SUCCESS
    FETCH_LEAD_APPOINTMENT_INFO_FAILURE

    FETCH_DS_OFFERED_PRICE_REQUEST
    FETCH_DS_OFFERED_PRICE_SUCCESS
    FETCH_DS_OFFERED_PRICE_FAILURE
    RESET_CAR_EVALUATION

    FETCH_APPOINTMENT_SLOT_REQUEST
    FETCH_APPOINTMENT_SLOT_SUCCESS
    FETCH_APPOINTMENT_SLOT_FAILURE
    SET_APPOINTMENT_SLOTS

    CONFIRM_LEAD_APPOINTMENT_REQUEST
    CONFIRM_LEAD_APPOINTMENT_SUCCESS
    CONFIRM_LEAD_APPOINTMENT_FAILURE

    TOGGLE_APPOINMENT_LOCATION_PICKER

    SUBMIT_CONDITION_QUESTION_REQUEST
    SUBMIT_CONDITION_QUESTION_SUCCESS
    SUBMIT_CONDITION_QUESTION_FAILURE

    UPDATE_PRICE_ACCEPTED_STATUS_SUCCESS

    CAR_SELF_INSPECTION_REQUEST
    CAR_SELF_INSPECTION_SUCCESS
    CAR_SELF_INSPECTION_FAILURE

    SET_SELLER_CAR_DETAILS

    TOGGLE_OTP_MODAL
    SET_IS_FETCH_INSPECTION_SLOTS
    SET_SELLER_RA
    SET_IS_SELLER_PREFERRED_DATE_SELECTION
    SET_INSPECTION_TYPE
    SET_OTHER_INFO

    SET_SUBSCRIPTION_BUYING_RESUME_URL
`,
    {
        prefix: "subscriptionvehicledetails/"
    }
);
