import React from "react";
import { Helmet } from "react-helmet-async";

// Components
import CarCareComponent from "../../../components/ae.mobile/car-care";
import withAELayout from "../../../components/ae.mobile/layout";
import ErrorHandler from "../../../components/shared/with-error-handler";

// Constants
import { appUrl } from "../../../constants/url-constants";

//Locale
import serviceSubscriptionLocale from "../../../locales/mobile/service-subscription";

const CarCare = () => {
    const baseUrl = `${appUrl()}/`;
    const { meta: metaLocale } = serviceSubscriptionLocale;

    return (
        <React.Fragment>
            <Helmet>
                <title>{metaLocale.title}</title>
                <meta name="description" content={metaLocale.description} />
                <meta name="ROBOTS" content="INDEX,FOLLOW" />
                <link href={`${baseUrl}`} rel="canonical" />
                <link rel="alternate" href="https://www.cars24.com/" hrefLang="en-IN" />
                <link rel="alternate" href="https://www.cars24.com/au/" hrefLang="en-AU" />
                <link rel="alternate" href="https://www.cars24.ae/" hrefLang="en-AE" />
            </Helmet>
            <ErrorHandler>
                <CarCareComponent />
            </ErrorHandler>
        </React.Fragment>
    );
};

export default withAELayout(CarCare, false, false);
