
export const b2cServicingEvents = {
    "tofScHomepageVisited": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_servicing",
        "eventAction": "sc_homepage_visited"
    },
    "tofScHomeSearch": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_servicing",
        "eventAction": "sc_home_search"
    },
    "tofScSearchResultClick": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_servicing",
        "eventAction": "sc_Search Result Click"
    },
    "tofScPopularBrand": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_servicing",
        "eventAction": "sc_popular_brand"
    },
    "tofScFaqAccordionExpanded": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_servicing",
        "eventAction": "sc_faq_accordion_expanded"
    },
    "tofScFaqViewAll": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_servicing",
        "eventAction": "sc_faq_view_all"
    },
    "tofScListingPageVisited": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_servicing",
        "eventAction": "sc_listing_page_visited"
    },
    "tofScAddScPackage": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_servicing",
        "eventAction": "add_sc_package"
    },
    "tofScRemoveScPackage": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_servicing",
        "eventAction": "remove_sc_package"
    },
    "tofScViewMore": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_servicing",
        "eventAction": "view_more"
    },
    "tofScQuestionMark": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_servicing",
        "eventAction": "question_mark"
    },
    "tofScHelpButton": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_servicing",
        "eventAction": "sc_help"
    },
    "tofScNextCtaListingPage": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_servicing",
        "eventAction": "Next_CTA_listing_page"
    },
    "tofScConfirmBookingPageCheckout": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_servicing",
        "eventAction": "sc_confirm_booking_page_checkout"
    },

    "servicingPageScrollInitiated": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_servicing",
        "eventAction": "SC_Scroll_Initiate"
    },
    "servicingPageScrollEnd": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_servicing",
        "eventAction": "SC_Scroll_End"
    },
    "servicingPageServiceAdded": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_servicing",
        "eventAction": "SC_Added"
    },
    "servicingPageScheduleClicked": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_servicing",
        "eventAction": "SC_ScheduleClick"
    },
    "servicingPageLocationSelected": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_servicing",
        "eventAction": "SC_LocationSelected"
    },
    "servicingPageDateSelected": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_servicing",
        "eventAction": "SC_DateSelected"
    },
    "servicingPageSlotSelected": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_servicing",
        "eventAction": "SC_SlotSelected"
    },
    "servicingPageScheduleProceed": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_servicing",
        "eventAction": "SC_ScheduleProceed"
    },
    "servicingPageEnterAddress": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_servicing",
        "eventAction": "SC_EnterAddress"
    },
    "servicingPageConfirmPickup": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_servicing",
        "eventAction": "SC_ConfirmPickup"
    },
    "servicingPageCancelService": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_servicing",
        "eventAction": "SC_Cancel"
    },
    "servicingPageRescheduleService": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_servicing",
        "eventAction": "SC_Reschedule"
    },
    "servicingPageContactUs": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_servicing",
        "eventAction": "SC_ContactUs"
    },
    "servicingPageContactModal": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_servicing",
        "eventAction": "SC_ContactModal"
    },
    "servicingPageCouponApply": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_servicing",
        "eventAction": "SC_CoupanApply"
    },

    "servicingTypesOfServiceViewMore": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_servicing",
        "eventAction": "sc_typeofservice_viewMore"
    },
    "servicingBlogReadMore": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_servicing",
        "eventAction": "sc_blog_readmore"
    }

};
