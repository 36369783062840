import { createReducer } from "../../../utils/redux/create-reducer";
import Types from "./types";

export const INITIAL_STATE = {
    make: {},
    model: {},
    year: "",
    variant: "",
    engineSize: "0.0",
    transmission: "",
    fuelType: "",
    noOfCylinders: 0,
    active: false,
    appointmentId: "",
    city: null,
    id: null,
    questions: {},
    showPrice: false,
    dsOfferedPrice: 0,
    status: null,
    vehicleType: "CAR",
    updatedAt: null,
    customer: {
        name: "",
        phone: "",
        whatsappConsent: true,
        whatsappConsentStatus: null
    },
    isGsEligible: false,
    isSoEligible: false,
    error: null,
    isLoading: false,
    isUpdating: false,
    locationName: "",
    dateTimeSlot: {},
    bookingDetail: {
        date: "",
        timeSlotBucket: "",
        startTime: "",
        endTime: "",
        reschedule: false,
        rescheduleCount: 0,
        address: {
            location: "",
            address1: "",
            address2: "",
            lat: 0,
            lng: 0,
            zipcode: ""
        },
        walkInAppointment: false
    },
    showAppointmentLocationPicker: false,
    isNotSure: false,
    carSelfInspection: null,
    sellerCarDetails: {},
    showOtpModal: false,
    nonEligibleReasons: [],
    ra: null,
    SPECIFICATION: {},
    ODOMETER_RANGE_READING: {},
    EMIRATE: {},
    resumeUrl: ""
};

const defaultCarDetails = {
    variant: "",
    engineSize: "0.0",
    transmission: "",
    fuelType: "",
    noOfCylinders: 0
};

export const initializeSubscriptionVehicleDetialsReducer = (state = INITIAL_STATE, { source = null }) => {

    return {
        ...state,
        source
    };
};

export const toggleOtpModal = (state = INITIAL_STATE, { flag }) => {
    return { ...state, showOtpModal: flag };
};

export const toggleAppointmentLocationPicker = (state = INITIAL_STATE, { flag }) => {
    return { ...state, showAppointmentLocationPicker: flag };
};

export const toggleSlotsApiCall = (state = INITIAL_STATE, { flag }) => {
    return { ...state, isFetchInspectionSlots: flag };
};

export const togglePreferredDateSelection = (state = INITIAL_STATE, { flag }) => {
    return { ...state, isPreferredDateSelection: flag };
};

export const setLeadBrand = (state = INITIAL_STATE, { data }) => {
    const { code, name } = data;
    let carEvaluationDetails = {};
    if (state?.make?.code !== code) {
        carEvaluationDetails = {
            model: {},
            year: "",
            ...defaultCarDetails,
            sellerCarDetails: { makeName: name }
        };
    } else {
        carEvaluationDetails = {
            ...state.sellerCarDetails, makeName: name
        };
    }
    return {
        ...state,
        ...carEvaluationDetails,
        make: { code, name }
    };
};

export const setLeadModel = (state = INITIAL_STATE, { data }) => {
    const { code, name } = data;
    let carEvaluationDetails = {};
    if (state?.model?.code !== code) {
        carEvaluationDetails = {
            year: "",
            ...defaultCarDetails,
            sellerCarDetails: { modelName: name, makeName: state?.make?.name }
        };
    } else {
        carEvaluationDetails = {
            ...state.sellerCarDetails, modelName: name
        };
    }
    return {
        ...state,
        ...carEvaluationDetails,
        model: { code, name }
    };
};

export const setLeadYear = (state = INITIAL_STATE, { year }) => {
    let carEvaluationDetails = {};
    if (year && +year.code !== +state?.year) {
        carEvaluationDetails = {
            ...defaultCarDetails,
            sellerCarDetails: { modelName: state?.model?.name, makeName: state?.make?.name, year: year?.name }
        };
    } else {
        carEvaluationDetails = {
            ...state.sellerCarDetails, year: year.name
        };
    }
    return {
        ...state,
        ...carEvaluationDetails,
        year: year.name
    };
};

export const setLeadData = (state = INITIAL_STATE, { key, value }) => {
    return {
        ...state,
        [key]: value,
        sellerCarDetails: {
            ...state.sellerCarDetails,
            [key]: value
        }
    };
};

export const setLeadDataByVariants = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        ...data
    };
};

export const setLeadCustomerData = (state = INITIAL_STATE, { customer }) => {
    return {
        ...state,
        customer
    };
};

export const setLeadBookingDetails = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        bookingDetail: data
    };
};
export const createLeadAppointmentRequest = (state = INITIAL_STATE) => {
    return {
        ...state,
        isUpdating: true
    };
};
export const createLeadAppointmentSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        ...data,
        isUpdating: false
    };
};
export const createLeadAppointmentFailure = (state = INITIAL_STATE, { error }) => {
    return {
        ...state,
        ...error,
        isUpdating: false
    };
};

export const updateLeadAppointmentRequest = (state = INITIAL_STATE) => {
    return {
        ...state,
        isUpdating: true
    };
};

export const updateLeadAppointmentSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        ...data,
        isUpdating: false
    };
};
export const updateLeadAppointmentFailure = (state = INITIAL_STATE, { error }) => {
    return {
        ...state,
        ...error,
        isUpdating: false
    };
};

export const fetchLeadAppointmentInfoRequest = (state = INITIAL_STATE) => {
    return {
        ...state,
        isLoading: true
    };
};

export const fetchLeadAppointmentInfoSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        ...data,
        isLoading: false
    };
};

export const fetchLeadAppointmentInfoFailure = (state = INITIAL_STATE, { error }) => {
    return {
        ...state,
        isLoading: false,
        error
    };
};

export const fetchAppointmentSlotsRequest = (state = INITIAL_STATE) => {
    return {
        ...state,
        isSlotsLoading: true,
        dateTimeSlot: {}
    };
};

export const fetchAppointmentSlotsSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        ...data,
        isSlotsLoading: false,
        slotsError: false
    };
};

export const fetchAppointmentSlotsFailure = (state = INITIAL_STATE, { error }) => {
    return {
        ...state,
        availableSlots: null,
        isSlotsLoading: false,
        slotsError: error || true
    };
};
export const confirmLeadAppointmentRequest = (state = INITIAL_STATE) => {
    return {
        ...state,
        isUpdating: true
    };
};
export const confirmLeadAppointmentSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        ...data,
        isUpdating: false
    };
};
export const confirmLeadAppointmentFailure = (state = INITIAL_STATE, { error }) => {
    return {
        ...state,
        ...error,
        isUpdating: false
    };
};

export const fetchDsOfferedPriceRequest = (state = INITIAL_STATE) => {
    return {
        ...state,
        isDSPriceLoading: true
    };
};

export const fetchDsOfferedPriceSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        ...data,
        isDSPriceLoading: false
    };
};

export const fetchDsOfferedPriceFailure = (state = INITIAL_STATE, { error }) => {
    return {
        ...state,
        ...error,
        isDSPriceLoading: false
    };
};
export const submitConditionQuestionRequest = (state = INITIAL_STATE) => {
    return {
        ...state,
        isUpdating: true
    };
};

export const submitConditionQuestionSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        ...data,
        isUpdating: false
    };
};

export const submitConditionQuestionFailure = (state = INITIAL_STATE, { error }) => {
    return {
        ...state,
        ...error,
        isUpdating: false
    };
};

export const updatePriceStatusSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        ...data
    };
};

export const carSelfInspectionRequest = (state = INITIAL_STATE) => {
    return {
        ...state,
        isLoading: true
    };
};
export const carSelfInspectionSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        carSelfInspection: data ? { ...data } : null,
        isLoading: false
    };
};
export const carSelfInspectionFailure = (state = INITIAL_STATE, { error }) => {
    return {
        ...state,
        ...error,
        isLoading: false
    };
};

export const setSellerCarDetails = (state = INITIAL_STATE, { data }) => {
    return { ...state, sellerCarDetails: data };
};

export const setAppointmentSlots = (state = INITIAL_STATE, { data }) => {
    return { ...state, dateTimeSlot: data };
};

export const setSellerRA = (state = INITIAL_STATE, { data }) => {
    return { ...state, ra: data };
};

export const setInspectionType = (state = INITIAL_STATE, { inspectionType }) => {
    return {
        ...state,
        inspectionType
    };
};

export const setOtherInfo = (state = INITIAL_STATE, { otherInfo }) => {
    return {
        ...state,
        ...otherInfo
    };
};

export const setSubscriptionBuyingResumeUrl = (state = INITIAL_STATE, { resumeUrl }) => {
    return {
        ...state,
        resumeUrl
    };
};

export const HANDLERS = {

    // dynamic injection
    [Types.INITIALIZE_SUBSCRIPTION_VEHICLE_DETAILS_REDUCER]: initializeSubscriptionVehicleDetialsReducer,

    [Types.SET_EVALUATION_BRAND]: setLeadBrand,
    [Types.SET_EVALUATION_MODEL]: setLeadModel,
    [Types.SET_EVALUATION_YEAR]: setLeadYear,
    [Types.SET_EVALUATION_DATA]: setLeadData,
    [Types.SET_EVALUATION_DATA_BY_VARIANTS]: setLeadDataByVariants,
    [Types.SET_EVALUATION_CUSTOMER]: setLeadCustomerData,
    [Types.FETCH_LEAD_APPOINTMENT_INFO_REQUEST]: fetchLeadAppointmentInfoRequest,
    [Types.FETCH_LEAD_APPOINTMENT_INFO_SUCCESS]: fetchLeadAppointmentInfoSuccess,
    [Types.FETCH_LEAD_APPOINTMENT_INFO_FAILURE]: fetchLeadAppointmentInfoFailure,

    [Types.CREATE_LEAD_APPOINTMENT_SUCCESS]: createLeadAppointmentSuccess,
    [Types.CREATE_LEAD_APPOINTMENT_REQUEST]: createLeadAppointmentRequest,
    [Types.CREATE_LEAD_APPOINTMENT_FAILURE]: createLeadAppointmentFailure,

    [Types.UPDATE_LEAD_APPOINTMENT_SUCCESS]: updateLeadAppointmentSuccess,
    [Types.UPDATE_LEAD_APPOINTMENT_REQUEST]: updateLeadAppointmentRequest,
    [Types.UPDATE_LEAD_APPOINTMENT_FAILURE]: updateLeadAppointmentFailure,

    [Types.CONFIRM_LEAD_APPOINTMENT_SUCCESS]: confirmLeadAppointmentSuccess,
    [Types.CONFIRM_LEAD_APPOINTMENT_REQUEST]: confirmLeadAppointmentRequest,
    [Types.CONFIRM_LEAD_APPOINTMENT_FAILURE]: confirmLeadAppointmentFailure,

    [Types.FETCH_DS_OFFERED_PRICE_REQUEST]: fetchDsOfferedPriceRequest,
    [Types.FETCH_DS_OFFERED_PRICE_SUCCESS]: fetchDsOfferedPriceSuccess,
    [Types.FETCH_DS_OFFERED_PRICE_FAILURE]: fetchDsOfferedPriceFailure,

    [Types.FETCH_APPOINTMENT_SLOT_REQUEST]: fetchAppointmentSlotsRequest,
    [Types.FETCH_APPOINTMENT_SLOT_SUCCESS]: fetchAppointmentSlotsSuccess,
    [Types.FETCH_APPOINTMENT_SLOT_FAILURE]: fetchAppointmentSlotsFailure,
    [Types.SET_APPOINTMENT_SLOTS]: setAppointmentSlots,

    [Types.SET_BOOKING_DETAILS]: setLeadBookingDetails,
    [Types.TOGGLE_APPOINMENT_LOCATION_PICKER]: toggleAppointmentLocationPicker,

    [Types.SUBMIT_CONDITION_QUESTION_REQUEST]: submitConditionQuestionRequest,
    [Types.SUBMIT_CONDITION_QUESTION_SUCCESS]: submitConditionQuestionSuccess,
    [Types.SUBMIT_CONDITION_QUESTION_FAILURE]: submitConditionQuestionFailure,

    [Types.UPDATE_PRICE_ACCEPTED_STATUS_SUCCESS]: updatePriceStatusSuccess,

    [Types.CAR_SELF_INSPECTION_REQUEST]: carSelfInspectionRequest,
    [Types.CAR_SELF_INSPECTION_SUCCESS]: carSelfInspectionSuccess,
    [Types.CAR_SELF_INSPECTION_FAILURE]: carSelfInspectionFailure,

    [Types.SET_SELLER_CAR_DETAILS]: setSellerCarDetails,

    [Types.TOGGLE_OTP_MODAL]: toggleOtpModal,
    [Types.SET_IS_FETCH_INSPECTION_SLOTS]: toggleSlotsApiCall,
    [Types.SET_SELLER_RA]: setSellerRA,
    [Types.SET_IS_SELLER_PREFERRED_DATE_SELECTION]: togglePreferredDateSelection,
    [Types.SET_INSPECTION_TYPE]: setInspectionType,
    [Types.SET_OTHER_INFO]: setOtherInfo,
    [Types.SET_SUBSCRIPTION_BUYING_RESUME_URL]: setSubscriptionBuyingResumeUrl

};

export default createReducer(INITIAL_STATE, HANDLERS);

