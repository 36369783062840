
import { C2bAppointmentService, SellerVariantService } from "../../../service";
import Types from "./types";
import { resetReducerKeys } from "../../../constants/app-constants";

const initializeSubscriptionVehicleDetialsReducer = (source) => {

    return ({
    type: Types.INITIALIZE_SUBSCRIPTION_VEHICLE_DETAILS_REDUCER,
    source
});
};

const toggleOtpModal = (flag) => (dispatch) => dispatch({
    type: Types.TOGGLE_OTP_MODAL,
    flag
});

const toggleAppointmentLocationPicker = (flag) => ({
    type: Types.TOGGLE_APPOINMENT_LOCATION_PICKER,
    flag
});

const setIsFetchInspectionSlots = (flag) => ({
    type: Types.SET_IS_FETCH_INSPECTION_SLOTS,
    flag
});

const setIsSellerPreferredDateSelection = (flag) => ({
    type: Types.SET_IS_SELLER_PREFERRED_DATE_SELECTION,
    flag
});

const setLeadBrand = ({ data }) => ({
    type: Types.SET_EVALUATION_BRAND,
    data
});

const setLeadModel = ({ data }) => ({
    type: Types.SET_EVALUATION_MODEL,
    data
});

const setLeadYear = ({ data }) => ({
    type: Types.SET_EVALUATION_YEAR,
    year: data
});

const setLeadCustomerData = ({ data }) => ({ // set data via key:value pair
    type: Types.SET_EVALUATION_CUSTOMER,
    customer: data
});

const setLeadDataNode = ({ data }) => ({ // set data via key:value pair
    type: Types.SET_EVALUATION_DATA,
    ...data
});

const setLeadBookingDetails = (data) => ({
    type: Types.SET_BOOKING_DETAILS,
    data
});

const resetLeadCreation = () => ({
    type: resetReducerKeys.RESET_SUBSCRIPTION_VEHICLE_DETAILS
});

const submitConditionQuestionRequest = () => ({
    type: Types.SUBMIT_CONDITION_QUESTION_REQUEST
});

const submitConditionQuestionSuccess = (data) => ({
    type: Types.SUBMIT_CONDITION_QUESTION_SUCCESS,
    data
});

const submitConditionQuestionFailure = (error) => ({
    type: Types.SUBMIT_CONDITION_QUESTION_FAILURE,
    error
});
const submitConditionQuestion = (appointmentId, payload) => (dispatch) => {
    dispatch(submitConditionQuestionRequest());
    return new Promise((resolve, reject) => {
        C2bAppointmentService.submitConditionQuestion(appointmentId, payload)
            .then(response => {
                dispatch(submitConditionQuestionSuccess(response.data));
                resolve(response.data);
            }).catch(error => {
                dispatch(submitConditionQuestionFailure(error));
                reject(error);
            });
    });
};

const sendAppointmentOtp = (appointmentId) => () => {
    return new Promise((resolve, reject) => {
        C2bAppointmentService.sendAppointmentOtp(appointmentId)
            .then(response => {
                resolve(response.data);
            }).catch(error => {
                reject(error);
            });
    });
};

const verifyAppointmentOtp = (appointmentId, payload) => () => {
    return new Promise((resolve, reject) => {
        C2bAppointmentService.verifyAppointmentOtp(appointmentId, payload)
            .then(response => {
                resolve(response.data);
            }).catch(error => {
                reject(error);
            });
    });
};

const carSelfInspectionRequest = () => ({
    type: Types.CAR_SELF_INSPECTION_REQUEST
});

const carSelfInspectionSuccess = (data) => ({
    type: Types.CAR_SELF_INSPECTION_SUCCESS,
    data
});

const carSelfInspectionFailure = (error) => ({
    type: Types.CAR_SELF_INSPECTION_FAILURE,
    error
});

const fetchCarSelfInspection = (payload) => (dispatch) => {
    dispatch(carSelfInspectionRequest());
    return new Promise((resolve, reject) => {
        SellerVariantService.fetchCarSelfInspectionQuestions(payload)
            .then(response => {
                dispatch(carSelfInspectionSuccess(response.data));
                resolve(response.data);
            }).catch(error => {
                dispatch(carSelfInspectionFailure(error));
                reject(error);
            });
    });
};

const setOtherInfo = (otherInfo) => {
    return {
        type: Types.SET_OTHER_INFO,
        otherInfo
    };
};

const setSubscriptionBuyingResumeUrl = (resumeUrl) => ({
    type: Types.SET_SUBSCRIPTION_BUYING_RESUME_URL,
    resumeUrl
});

export {
    initializeSubscriptionVehicleDetialsReducer,
    toggleOtpModal,
    toggleAppointmentLocationPicker,
    setLeadBrand,
    setLeadModel,
    setLeadYear,
    setLeadDataNode,
    setLeadBookingDetails,
    submitConditionQuestion,
    sendAppointmentOtp,
    verifyAppointmentOtp,
    setLeadCustomerData,
    resetLeadCreation,
    fetchCarSelfInspection,
    carSelfInspectionSuccess,
    setIsFetchInspectionSlots,
    setIsSellerPreferredDateSelection,
    setOtherInfo,
    setSubscriptionBuyingResumeUrl
};
