import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// Component
import PostBookingWhatsAppNudge from "./component";
import { INITIAL_STATE as postBCInitialState } from "../post-booking-confirmation-v2/reducers";

const mapStateToProps = ({
    postBcDetails = postBCInitialState,
    tofService: {
        serviceOrder
    } = {}
}) => ({
    postBcDetails,
    serviceOrder
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PostBookingWhatsAppNudge);
