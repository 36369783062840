import addDays  from "date-fns/addDays";
import differenceInDays from "date-fns/differenceInDays";
import isAfter from "date-fns/isAfter";
import isBefore from "date-fns/isBefore";
import { NUMBER } from "../../constants/app-constants";
import { formatDate, DATE_FORMAT_TYPE } from "./get-day-from-date";

export default (dateLeft, dateRight) => {
    return differenceInDays(dateLeft, dateRight) + NUMBER.ONE > 0 ? differenceInDays(dateLeft, dateRight) + NUMBER.ONE : -1;
};

export const isSaleLive = (startDate, endDate) => {
    return isBefore(endDate, startDate);
};

export const getTestDriveScheduleTime = (date) => {
    if (isAfter(new Date(date), addDays(new Date(), 1))) {
        return formatDate(new Date(date), DATE_FORMAT_TYPE.WEEK_DATE_MONTH);
    } else if (isAfter(new Date(date), new Date())) {
        return "Tomorrow";
    } else {
        return "Today";
    }
};

export const daysBetweenDates = (dateLeft, dateRight) => differenceInDays(dateLeft, dateRight);
