import Types from "./types";
import { createReducer } from "../../../utils/redux/create-reducer";

const SUBSCRIPTION_ORDER_INITIAL_STATE = {
    subscriptionOrderId: "",
    isOrderLoading: false,
    isOrderFetched: false,
    planAdditionInProgress: false
};

export const INITIAL_STATE = {
    pageData: {
        isCarCarePageDataLoading: false,
        isCarCarePageDataFetched: false,
        pricingDetails: {
            saveUpto: 5000,
            monthlyEmi: 399,
            tenure: 24
        },
        services: [],
        serviceDetails: {}
    },
    subscriptionOrder: SUBSCRIPTION_ORDER_INITIAL_STATE,
    subscriptionPlans: {
        isPlansFetching: false,
        isPlansFetched: false,
        planList: [],
        subscriptionPlanDetails: {}
    },
    subscriptionOrderList: {
        isOrdersListLoading: false,
        isOrdersListFetched: false
    },
    showHelpNudge: true,
    activeStepData: {
        stepName: "",
        isStepFetching: false,
        data: null
    },
    source: "CLIENT_SIDE"
};

// dynamic injection
export const initializeCarCareReducer = (state = INITIAL_STATE, { source = null }) => {

    return {
        ...state,
        source
    };
};

// PAGE DATA : Car Care
const fetchCarCarePageDataInit = (state = INITIAL_STATE, { withLoader }) => {
    return {
        ...state,
        pageData: {
            ...state.pageData,
            isCarCarePageDataLoading: withLoader,
            isCarCarePageDataFetched: !withLoader
        }
    };
};

export const fetchCarCarePageDataSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        pageData: {
            ...state.pageData,
            isCarCarePageDataLoading: false,
            isCarCarePageDataFetched: true,
            ...data
        }
    };
};

const fetchCarCarePageDataFailure = (state = INITIAL_STATE) => {
    return {
        ...state,
        pageData: {
            ...state.pageData,
            isCarCarePageDataLoading: false,
            isCarCarePageDataFetched: true
        }
    };
};

// SUBSCRIPTION ALL ORDER : Car Care
const fetchAllSubscriptionOrdersInit = (state = INITIAL_STATE, { withLoader }) => {
    return {
        ...state,
        subscriptionOrderList: {
            ...state.subscriptionOrderList,
            isOrdersListLoading: withLoader,
            isOrdersListFetched: !withLoader
        }
    };
};

export const fetchAllSubscriptionOrdersSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        subscriptionOrderList: {
            ...state.subscriptionOrderList,
            isOrdersListLoading: false,
            isOrdersListFetched: true,
            ...data
        }
    };
};

const fetchAllSubscriptionOrdersFailure = (state = INITIAL_STATE) => {
    return {
        ...state,
        subscriptionOrderList: {
            ...state.subscriptionOrder,
            isOrdersListLoading: false,
            isOrdersListFetched: true
        }
    };
};

// SUBSCRIPTION ORDER : Car Care

const resetSubscriptionOrderData = (state = INITIAL_STATE) => {
    return {
        ...state,
        subscriptionOrder: { ...SUBSCRIPTION_ORDER_INITIAL_STATE }
    };
};

const fetchSubscriptionOrderDataInit = (state = INITIAL_STATE, { withLoader }) => {
    return {
        ...state,
        subscriptionOrder: {
            ...state.subscriptionOrder,
            isOrderLoading: withLoader,
            isOrderFetched: !withLoader
        }
    };
};

export const fetchSubscriptionOrderDataSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        subscriptionOrder: {
            ...state.subscriptionOrder,
            isOrderLoading: false,
            isOrderFetched: true,
            ...data
        }
    };
};

const fetchSubscriptionOrderDataFailure = (state = INITIAL_STATE) => {
    return {
        ...state,
        subscriptionOrder: {
            ...state.subscriptionOrder,
            isOrderLoading: false,
            isOrderFetched: true
        }
    };
};

// SUBSCRIPTION Plans : Car Care
const fetchSubscriptionPlansInit = (state = INITIAL_STATE, { withLoader }) => {
    return {
        ...state,
        subscriptionPlans: {
            ...state.subscriptionPlans,
            isPlansFetching: withLoader,
            isPlansFetched: !withLoader
        }
    };
};

export const fetchSubscriptionPlansSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        subscriptionPlans: {
            ...state.subscriptionPlans,
            isPlansFetching: false,
            isPlansFetched: true,
            planList: data
        }
    };
};

const fetchSubscriptionPlansFailure = (state = INITIAL_STATE) => {
    return {
        ...state,
        subscriptionPlans: {
            ...state.subscriptionPlans,
            isPlansFetching: false,
            isPlansFetched: true
        }
    };
};

// SUBSCRIPTION Plans Adding to order   : Car Care
const addPlanToSubscriptionOrderInit = (state = INITIAL_STATE, { withLoader }) => {
    return {
        ...state,
        subscriptionOrder: {
            ...state.subscriptionOrder,
            isPlanAdding: withLoader
        }
    };
};

export const addPlanToSubscriptionOrderSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        subscriptionOrder: {
            ...state.subscriptionOrder,
            isPlanAdding: false,
            subscriptionPlanDetails: data
        }
    };
};

const addPlanToSubscriptionOrderFailure = (state = INITIAL_STATE) => {
    return {
        ...state,
        subscriptionOrder: {
            ...state.subscriptionOrder,
            isPlanAdding: false
        }
    };
};

// SUBSCRIPTION help Nudge Management
export const setShowHelpNudgeForCarCare = (state = INITIAL_STATE, { showHelpNudge }) => {
    return {
        ...state,
        showHelpNudge
    };
};

// SUBSCRIPTION Active step Data connect
const fetchSubscriptionOrderActiveStepDataInit = (state = INITIAL_STATE, { withLoader, stepName }) => {
    return {
        ...state,
        activeStepData: {
            ...state.activeStepData,
            stepName,
            isStepFetching: withLoader,
            data: null
        }
    };
};

export const fetchSubscriptionOrderActiveStepDataSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        activeStepData: {
            ...state.activeStepData,
            isStepFetching: false,
            data
        }
    };
};

const fetchSubscriptionOrderActiveStepDataFailure = (state = INITIAL_STATE) => {
    return {
        ...state,
        subscriptionPlans: {
            ...state.activeStepData,
            isStepFetching: false
        }
    };
};

export const updateSuperAppVersion = (state = INITIAL_STATE, { superAppVersion }) => {
    return { ...state, superAppVersion };
};

export const HANDLERS = {

    // dynamic injection
    [Types.INITIALIZE_CAR_CARE_REDUCER]: initializeCarCareReducer,

    // PAGE DATA : Car Care
    [Types.FETCH_CAR_CARE_PAGE_DATA_INIT]: fetchCarCarePageDataInit,
    [Types.FETCH_CAR_CARE_PAGE_DATA_SUCCESS]: fetchCarCarePageDataSuccess,
    [Types.FETCH_CAR_CARE_PAGE_DATA_FAILURE]: fetchCarCarePageDataFailure,

    // SUBSCRIPTION ALL ORDER : Car Care
    [Types.FETCH_ALL_SUBSCRIPTION_ORDER_INIT]: fetchAllSubscriptionOrdersInit,
    [Types.FETCH_ALL_SUBSCRIPTION_ORDER_SUCCESS]: fetchAllSubscriptionOrdersSuccess,
    [Types.FETCH_ALL_SUBSCRIPTION_ORDER_FAILURE]: fetchAllSubscriptionOrdersFailure,

    // SUBSCRIPTION ORDER : Car Care
    [Types.RESET_SUBSCRIPTION_ORDER]: resetSubscriptionOrderData,
    [Types.FETCH_SUBSCRIPTION_ORDER_INIT]: fetchSubscriptionOrderDataInit,
    [Types.FETCH_SUBSCRIPTION_ORDER_SUCCESS]: fetchSubscriptionOrderDataSuccess,
    [Types.FETCH_SUBSCRIPTION_ORDER_FAILURE]: fetchSubscriptionOrderDataFailure,

    // SUBSCRIPTION Plans : Car Care
    [Types.FETCH_SUBSCRIPTION_PLANS_INIT]: fetchSubscriptionPlansInit,
    [Types.FETCH_SUBSCRIPTION_PLANS_SUCCESS]: fetchSubscriptionPlansSuccess,
    [Types.FETCH_SUBSCRIPTION_PLANS_FAILURE]: fetchSubscriptionPlansFailure,

    // SUBSCRIPTION Plans Adding to order : Car Care
    [Types.ADD_PLAN_TO_SUBSCRIPTION_ORDER_INIT]: addPlanToSubscriptionOrderInit,
    [Types.ADD_PLAN_TO_SUBSCRIPTION_ORDER_SUCCESS]: addPlanToSubscriptionOrderSuccess,
    [Types.ADD_PLAN_TO_SUBSCRIPTION_ORDER_FAILURE]: addPlanToSubscriptionOrderFailure,

    // SUBSCRIPTION help Nudge Management
    [Types.SET_SHOW_HELP_NUDGE]: setShowHelpNudgeForCarCare,

    // SUBSCRIPTION Active step Data connect
    [Types.FETCH_ACTIVE_STEP_DATA_INIT]: fetchSubscriptionOrderActiveStepDataInit,
    [Types.FETCH_ACTIVE_STEP_DATA_SUCCESS]: fetchSubscriptionOrderActiveStepDataSuccess,
    [Types.FETCH_ACTIVE_STEP_DATA_FAILURE]: fetchSubscriptionOrderActiveStepDataFailure,

    // SuperAppVersion

    [Types.UPDATE_SUPER_APP_VERSION]: updateSuperAppVersion
};

export default createReducer(INITIAL_STATE, HANDLERS);
