/* eslint-disable max-statements */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// Styling
import styles from "./styles.css";

// hooks
import useCustomHistoryOperations from "../../../hooks/use-custom-history-operations";

// Components
import ServiceSubscriptionLayout from "../service-subscription-layout";
import Header from "./header";
import SubscriptionMonthlyPlanCard from "./subscription-monthly-plan-card";
import SubscriptionBenefits from "./subscription-benefits";
import PageLoader from "../../shared/page-loader";

// images

// Constants
import { ROUTE_SUB_TYPE, ROUTE_TYPE } from "../car-care/constants";

// Utils
import { generateCarCareRoute, getLastestSubscriptionFromList, isSubscriptionOrderJourneyCompleted } from "../car-care/utils";

// Tracking
import { trackWebViewEvents } from "../../../utils/webview-tracking";
import { WEBVIEW_EVENTS } from "../../../constants";
import { trackPlatformCustomEventsAEV2 } from "../../../tracking";
import { subscriptionEvents } from "../../../tracking/msite/b2c-subscription-flow-events";
import { useLocation } from "react-router";
import parseLocationSearchParams from "../../../utils/helpers/parse-location-search-params";

const ServiceSubscriptionHome = ({
    pageData,
    isLoggedIn,
    isUserDetailsFetched,
    isWebview,
    fetchAllSubscriptionOrdersConnect,
    subscriptioBuyingResumeUrl,
    superAppVersion,
    isBottomNavVisible
}) => {

    const { customPush, customReplace } = useCustomHistoryOperations();
    const { search } = useLocation();
    const queryParams = parseLocationSearchParams(search);

    const [checkingSubscriptionOrder, setCheckingSubscriptionOrder] = useState(isLoggedIn || !!queryParams?.token);

    const { pricingDetails, services } = pageData || {};

    const fetchAllOrderAndRedirect = async () => {
        try {
            const subscriptionOrderList = await fetchAllSubscriptionOrdersConnect(null, { showErrorToast: true });
            const latestSubscriptionOrder = getLastestSubscriptionFromList(subscriptionOrderList);
            const { subscriptionOrderId, status: subscriptionOrderStatus, subscriptionPlanDetails } = latestSubscriptionOrder || {};

            if (latestSubscriptionOrder && !isSubscriptionOrderJourneyCompleted(subscriptionOrderStatus)) {

                trackWebViewEvents(WEBVIEW_EVENTS.HIDE_TOP_TAB);

                if (!subscriptionPlanDetails) {
                    customReplace(generateCarCareRoute(ROUTE_TYPE.PLAN_LIST, ROUTE_SUB_TYPE.PLAN_LIST.FETCHING_PLANS, { subscriptionOrderId }));
                } else {
                    customReplace(generateCarCareRoute(ROUTE_TYPE.ORDER_SUMMARY, ROUTE_SUB_TYPE.ORDER_SUMMARY.ANCHOR_PAGE, { subscriptionOrderId }));
                }
            } else {
                setCheckingSubscriptionOrder(false);
            }
        // eslint-disable-next-line no-empty
        } catch (err) {}
    };

    useEffect(() => {
        trackWebViewEvents(WEBVIEW_EVENTS.SHOW_TOP_TAB);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (isUserDetailsFetched && isLoggedIn) {
            fetchAllOrderAndRedirect();
        }

        if (isUserDetailsFetched) {
            trackPlatformCustomEventsAEV2(subscriptionEvents.subscriptionPageLand, { eventLabel: isLoggedIn ? "loggedIn" : "notLoggedIn" });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoggedIn, isUserDetailsFetched]);

    const onBackClick = () => {
        if (isWebview) {
            trackWebViewEvents(WEBVIEW_EVENTS.SHOW_TOP_TAB);
            trackWebViewEvents(WEBVIEW_EVENTS.HEADER_BACK);
        } else {
            customPush("/");
        }
    };

    const onTermsAndConditionsClick = () => {
        trackWebViewEvents(WEBVIEW_EVENTS.HIDE_TOP_TAB);
        trackPlatformCustomEventsAEV2(subscriptionEvents.termsClick);
        customPush(generateCarCareRoute(ROUTE_TYPE.TERMS_CONDITIONS));
    };

    const onUnlockPriceClick = () => {
        trackWebViewEvents(WEBVIEW_EVENTS.HIDE_TOP_TAB);
        customPush(generateCarCareRoute(ROUTE_TYPE.CAR_DETAILS, ROUTE_SUB_TYPE.CAR_DETAILS.Brand));
    };

    const getCtaProps = () => {

        if (subscriptioBuyingResumeUrl) {
            return {
                text: "CONTINUE ADDING DETAILS",
                onCtaClick: () => {
                    trackWebViewEvents(WEBVIEW_EVENTS.HIDE_TOP_TAB);
                    trackPlatformCustomEventsAEV2(subscriptionEvents.continueAddingDetailsClick);
                    customPush(subscriptioBuyingResumeUrl);
                }
            };
        }

        return {
            text: "UNLOCK PRICE",
            onCtaClick: () => {
                trackPlatformCustomEventsAEV2(subscriptionEvents.unlockPriceClick);
                onUnlockPriceClick();
            }
        };
    };

    const onTncClick = () => {
        trackWebViewEvents(WEBVIEW_EVENTS.HIDE_TOP_TAB);
        trackPlatformCustomEventsAEV2(subscriptionEvents.seeAllDetailsClick);
        customPush(generateCarCareRoute(ROUTE_TYPE.TERMS_CONDITIONS));
    };

    const ctaPorps = getCtaProps();

    return (
        checkingSubscriptionOrder ?
            <PageLoader />
            :
            <ServiceSubscriptionLayout
                customHeaderComponentRenderer={() => <Header showBackBtn={superAppVersion !== "v2"} onBackClick={onBackClick}/>}
                stickCtaText={ctaPorps?.text}
                onStickyCtaClick={ctaPorps?.onCtaClick}
                onLoginCloseCallBack={() => { trackWebViewEvents(WEBVIEW_EVENTS.SHOW_TOP_TAB); }}
                isBottomNavVisible={isBottomNavVisible}
            >
                <React.Fragment>
                    <SubscriptionMonthlyPlanCard pricingDetails={pricingDetails}  />
                    <SubscriptionBenefits services={services} tncText={"See All Details"} onTncClick={onTncClick} />
                    <div styleName={`styles.tnCtextWrapper ${isBottomNavVisible ? (isLoggedIn ? "styles.tnCtextWrapperOnLoggedInBottomNavVisible" : "styles.tnCtextWrapperOnBottomNavVisbile") : ""} ${isLoggedIn && !isBottomNavVisible ? "styles.tnCtextWrapperOnLoggedIn" : ""}`}>
                        <p>*One-time enrolment fee not included. <span onClick={onTermsAndConditionsClick}>T&C Apply</span></p>
                    </div>
                </React.Fragment>
            </ServiceSubscriptionLayout>
    );
};

ServiceSubscriptionHome.propTypes = {
    pageData: PropTypes.object,
    isLoggedIn: PropTypes.bool,
    isUserDetailsFetched: PropTypes.bool,
    isWebview: PropTypes.bool,
    fetchAllSubscriptionOrdersConnect: PropTypes.func,
    subscriptioBuyingResumeUrl: PropTypes.string,
    superAppVersion: PropTypes.string,
    isBottomNavVisible: PropTypes.bool
};

export default (ServiceSubscriptionHome);
